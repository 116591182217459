import React from "react"
import { graphql, PageProps } from "gatsby"

import { useAnalytics } from "../hooks"

import {
  HeaderHomepage,
  SectionsHandler,
  Layout,
  SEO,
  SignOffStillLooking,
  GoogleReviews,
} from "../components"
import { pageType } from "../types"

type Props = PageProps & {
  data: pageType & {
    allContentfulCourseTopics: {
      edges: Array<{
        node: {
          slug: string
          name: string
          contentful_id: string
        }
      }>
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()

  const url = `${process.env.GATSBY_SITE_URL}`
  analytics.track(["User", "Page View"], { page: "/" })

  return (
    <Layout>
      <SEO
        title={data.contentfulPages.seo.title}
        description={data.contentfulPages.seo.description.description}
        image={data.contentfulPages.seo.socialImage?.file?.url}
        url={url}
      />
      <HeaderHomepage
        heading={data.contentfulPages.heading}
        courseTopics={data.allContentfulCourseTopics.edges}
      />
      <main>
        <section className="backgroundGreyLightSuper">
          <GoogleReviews variant="homepage" />
        </section>
        <SectionsHandler sections={data.contentfulPages.sections} />
      </main>
      <SignOffStillLooking />
    </Layout>
  )
}

export const pageQuery = graphql`
  query getLandingPageContent {
    allContentfulCourseTopics(sort: { fields: name, order: ASC }) {
      edges {
        node {
          slug
          name
          contentful_id
        }
      }
    }
    contentfulPages(page: { eq: "Homepage" }) {
      contentful_id
      heading
      headerIntroduction {
        raw
      }
      headerImage {
        gatsbyImageData
        title
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
      sections {
        ... on ContentfulPageSectionsCourseGroups {
          contentful_id
          name
          __typename
          courseGroups {
            ... on ContentfulCourseGroups {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                title
                gatsbyImageData
              }
              contentful_id
              slug
            }
            ... on ContentfulCourseTopics {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                gatsbyImageData
                title
              }
              contentful_id
              slug
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsFaQs {
          __typename
          contentful_id
          heading
          faQs {
            heading
            content {
              raw
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsHowItWorks {
          __typename
          contentful_id
          heading
          steps {
            contentful_id
            description {
              description
            }
            heading
            icon {
              file {
                url
              }
              gatsbyImageData
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStats {
          __typename
          contentful_id
          stats {
            name
            description {
              description
            }
            number
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsTextWithImage {
          __typename
          id
          contentful_id
          image {
            gatsbyImageData
            title
            url
          }
          video {
            url
            title
          }
          imageAlignment
          heading
          content {
            raw
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStrap {
          __typename
          id
          contentful_id
          buttonSupportingText
          description
          name
          buttonText
          buttonUrl
          backgroundColour
        }
      }
    }
  }
`
